.fill-height {
	height: 100vh;
}

.date {
	text-align: center;
	margin-bottom: 30px;
}

.container-grid {
	padding: 20px;
	overflow: hidden;
}

.container-grid:nth-child(even) {
	background-color: var(--secondary-color);
}

.container-grid h2 {
	margin-top: 0;
}

.show-grid-container {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
	grid-gap: 20px;
}

.show-container {
	text-align: center;
	animation-name: example;
	animation-duration: 1s;
}

.show-meta {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 15rem;
}

.show-imdb {
	transform: scale(0.8);
}

.show-tvdb {
	background-color: #1c7e3e;
	padding: 2px 10px;
	border-radius: 5px;
	font-weight: bold;
	font-size: 16rem;
}

.show-rating {
	display: flex;
	align-items: center;
}

.show-rating svg {
	transform: scale(0.5);
}

.show-title {
	margin-top: 10px;
}

.show-info img {
	width: 100%;
	box-shadow: 0 0 10px 4px var(--box-shadow-color);
}

.show-desc {
	padding: 5px 0;
	min-height: 120px;
}

.airtime {
	padding: 20px 0;
}

.show-network {
	padding-bottom: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@keyframes example {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
