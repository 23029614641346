body {
	display: grid;
	grid-template-areas:
		'header main'
		'header footer';
	/* grid-template-columns: 200px 1fr; */
	grid-template-columns: 250px 1fr;
	/* grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); */
	/* grid-template-rows: auto 1fr auto; */
	/* grid-gap: 10px; */

	/* height: 100vh; */
}

header {
	grid-area: header;
}

main {
	grid-area: main;
}

footer {
	grid-area: footer;
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%) scale(5);
}

@media (max-width: 600px) {
	body {
		display: grid;
		grid-template-columns: 50px 1fr;
	}

	.loader {
		top: 500px;
	}
}
