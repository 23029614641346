html{ 
  font-size: 1px;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: var(--primary-font-color);
  font-size: 18rem;
}

h1 {
  font-size: 35rem;
  font-weight: 600;
}

h2 {
  font-size: 30rem;
  font-weight: 700;
}

h3 {
  font-size: 20rem;
  font-weight: 600;
}

h4 {
  font-size: 20rem;
}

.nav-days {
  font-size: 22rem;
  font-weight: 600;
}