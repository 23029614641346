footer {
  border-top: 4px solid #F83646;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
    text-align: center;
  }

  footer p {
    margin: 5px;
  }
}