header {
  position: fixed;
  background-color: var(--secondary-color);
  height: 100vh;
  width: 250px;
  transition: margin-left .5s;
  padding: 10px 0;
  z-index: 100;
  overflow: auto;
}

nav button {
  padding: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  outline: none;
  font-weight: bold;
}

.active {
  background: #222A31;
  box-shadow: -20px 0 11px 2px #151515;
  color: var(--highlight-color);
}

.title {
  margin: 20px 0 40px;
  text-align: center;
}

.show-category {
  pointer-events: none;
}

form {
  margin: 20px;
}

fieldset label {
  cursor: pointer;
}

form select, form fieldset {
  margin-bottom: 20px;
}

.nav-container {
  transition: width .3s;
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

/* custom radio buttons */
.radio-pillbox {
  display: flex;
  text-align: center;
}

input[type=radio] {
  display:none; 
  margin:10px;
}

input[type=radio] + label {
  display:inline-block;
  margin: 10px 0;
  padding: 10px;
  background-color: var(--primary-color);
  border-radius: 5px;
}

input[type=radio]:checked + label { 
  background-color: var(--secondary-color);
  border: 1px solid var(--highlight-color);
}
/* end custom radio buttons */
/* customCheckbox */

.customCheckbox {
  background: var(--primary-color);
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18rem;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 2px solid transparent;
}

.checkboxSelected {
  border-left: 2px solid var(--highlight-color);
  border-right: 2px solid var(--highlight-color);
}

.customCheckbox label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  background: -webkit-gradient(linear, left top, left bottom, from(#222222), to(#45484d));
  background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
}
.customCheckbox label:after {
  content: '';
  width: 20px;
  height: 9px;
  position: absolute;
  top: 2px;
  left: 4px;
  border: 3px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.customCheckbox:hover label::after {
  opacity: 0.3;
}
.customCheckbox input[type=checkbox] {
  visibility: hidden;
}
.customCheckbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}
/* end customCheckbox */
/* hamburger menu */

.menu-wrapper {
  position: absolute;
  right: -25px;
  transform: scale(.2);
  cursor: pointer;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 100px;
  height: 10px;
}

.hamburger-menu {
  position: relative;
  transform: translateY(25px);
  background: white;
  transition: all 0ms 300ms;
}
.hamburger-menu.animate {
  background: rgba(255, 255, 255, 0);
}

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 25px;
  background: white;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 25px;
  background: white;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}


/* endhamburger menu */

@media (max-width: 600px) {
  header {
    margin-left: -200px;
    height: 100%;
  }
 
  .menu-toggle {
    display: block;
    text-align: right;
  }

  .nav-container {
    width: 200px;
  }
    
  .toggle-open {
    margin-left: 0;
  }

  .toggle-closed {
    overflow: hidden;
  }

  .toggle-open .nav-container {
    width: 100%;
  }

}